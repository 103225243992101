<template>
  <div>
    <div class="pb-1 d-flex align-center">
      <v-text-field
        class="c-input-small"
        v-model="filters.keywords"
        @keyup.enter="getList"
        :label="$t('labels.uid')"
        :placeholder="$t('labels.uid')"
        dense
        outlined
        clearable
        hide-details
        single-line
        append-icon="mdi-qrcode-scan"
        @click:append="showQRCodeScan('keywords')"
      ></v-text-field>
    </div>

    <v-list
      dense
      style="height: calc(100vh - 170px)"
      class="overflow-x-hidden overflow-y-auto"
    >
      <div v-for="(item, index) in items" :key="`${item.id}_${index}`">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-list-item-subtitle class="font-weight-regular">
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.uid") }}: &nbsp;</span
              >
              <span
                v-if="
                  checkPermission(['goods_uid_roll']) &&
                  [1, 2, 3].includes(item.status)
                "
                class="cursor-pointer font-weight-medium primary--text text-decoration-underline"
                @click="showRollUid(item)"
                >{{ item.sku }}@{{ item.uid }}</span
              >
              <span v-else class="font-weight-medium black--text"
                >{{ item.sku }}@{{ item.uid }}</span
              >
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.barcode") }}: &nbsp;</span
              >
              <span class="font-weight-medium error--text">{{
                item.customer_goods_barcode
              }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.employee") }}: &nbsp;</span
              >
              <span class="black--text">{{ item.employee_name }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.available") }}: &nbsp;</span
              >
              <span class="black--text">{{
                item.available ? "Yes" : "No"
              }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.status") }}: &nbsp;</span
              >
              <span class="black--text">{{ statusTxt[item.status] }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="font-weight-regular"
              v-if="item.tracking_goods_receipt_history"
            >
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.import") }}: &nbsp;</span
              >
              <span class="font-weight-medium">{{
                item.tracking_goods_receipt_history
              }}</span>
              {{
                item.tracking_goods_receipt_history
                  ? formatDateTime(item.created_at)
                  : ""
              }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="font-weight-regular"
              v-if="item.tracking_goods_issue_detail"
            >
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.export") }}: &nbsp;</span
              >
              <OrderTracking
                v-if="item.tracking_goods_issue_detail"
                :tracking-id="item.tracking_goods_issue_detail"
              />
              {{ item.pickup_at ? ` - ${formatDateTime(item.pickup_at)}` : "" }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="font-weight-regular"
              v-if="item.tracking_goods_issue_detail_return"
            >
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.return") }}: &nbsp;</span
              >
              <OrderTracking
                v-if="item.tracking_goods_issue_detail_return"
                :tracking-id="item.tracking_goods_issue_detail_return"
              />
              {{ item.return_at ? ` - ${formatDateTime(item.return_at)}` : "" }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="font-weight-regular"
              v-if="item.expired_date"
            >
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.expired_date") }}: &nbsp;</span
              >
              <span class="font-weight-medium">{{
                item.expired_date
                  ? formatDateTime(item.expired_date, "DD/MM/YYYY")
                  : ""
              }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="font-weight-regular"
              v-if="item.lot_tracking"
            >
              <span class="d-inline-block text-right" style="min-width: 70px"
                >{{ $t("labels.lot_tracking") }}: &nbsp;</span
              >
              <span class="font-weight-medium">{{ item.lot_tracking }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index < items.length - 1"></v-divider>
      </div>
    </v-list>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="true"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>

    <v-dialog v-model="rollUidDialog" persistent max-width="350px">
      <RollUid
        v-if="rollUidDialog"
        :item="updatingItem"
        @cancel="hideRollUid"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import uid from "@/components/goods/mixins/uid";

export default {
  name: "UidMobile",
  components: {
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
    OrderTracking: () => import("@/components/common/OrderTracking"),
    RollUid: () => import("@/components/goods/RollUid"),
  },
  mixins: [uid],
};
</script>

<style scoped></style>
