import { render, staticRenderFns } from "./UidMobile.vue?vue&type=template&id=847639d2&scoped=true"
import script from "./UidMobile.vue?vue&type=script&lang=js"
export * from "./UidMobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "847639d2",
  null
  
)

export default component.exports